<template>
  <section>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="4">
            <e-store-combo
              id="filters-store"
              v-model="filters.storeId"
              :required="false"
              :placeholder="$t('Todas')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="region"
              v-model="filters.region"
              type="vue-select"
              :label="$t('Região')"
              :options="storeRegions()"
              :placeholder="$t('Todas')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="province"
              v-model="filters.province"
              type="vue-select"
              :label="$t('Estado')"
              :options="provincesWithStores()"
              :placeholder="$t('Todos')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="city"
              v-model="filters.city"
              type="text"
              :label="$t('Cidade')"
            />
          </b-col>
          <b-col md="4">
            <e-search-sku
              id="filters-product"
              v-model="filters.skuId"
              :required="false"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="name"
              v-model="filters.name"
              type="text"
              :label="$t('Nome')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="period"
              v-model="filters.period"
              type="date-range-picker"
              :label="$t('Período')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="active"
              v-model="filters.active"
              type="vue-select"
              :label="$t('Status')"
              :placeholder="$t('Todos')"
              :options="activeInactiveTypes()"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        ref="promotions-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('PROMOTION.NAME'), 'female')"
        :fields="fields"
        :items="promotions"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(active)="row">
          <e-status-badge :status="row.item.active" />
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :is-active="row.item.active"
            :show-update="$can('Update', 'Promotion')"
            :show-delete="false"
            :show-activate="$can('Activate', 'Promotion')"
            :show-deactivate="$can('Deactivate', 'Promotion')"
            @update="onUpdatePromotion(row)"
            @activate="activateDeactivatePromotion(row)"
            @deactivate="activateDeactivatePromotion(row)"
          />
        </template>
      </b-table>

      <b-row class="pt-1">
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="product-kit-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <fab
      v-if="$can('Create', 'Promotion')"
      :main-tooltip="$t('Adicionar Promoção')"
      @click="onCreatePromotion"
    />
  </section>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary, EStoreCombo } from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { province, statusTypes, storeDomains } from '@/mixins'
import { mapActions, mapState } from 'vuex'
import EFilters from '@/views/components/EFilters.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import ESearchSku from '@/views/components/inputs/ESearchSku.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPageSizeSelector,
    EPaginationSummary,
    fab,
    EFilters,
    EGridActions,
    EStatusBadge,
    EStoreCombo,
    ESearchSku,
  },

  mixins: [statusTypes, province, storeDomains],

  data() {
    return {
      fetching: false,
      edit: false,
      saving: false,
    }
  },

  computed: {
    ...mapState('pages/sale/promotion', ['promotions', 'paging', 'sorting', 'filters']),

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Id'),
          key: 'id',
          tdClass: 'text-right',
          thStyle: { width: '80px' },
          sortable: true,
        },
        {
          label: this.$t('Início'),
          key: 'startDate',
          tdClass: 'text-center',
          thStyle: { width: '160px' },
          formatter: value => this.$options.filters.datetime(value),
          sortable: true,
        },
        {
          label: this.$t('Fim'),
          key: 'endDate',
          tdClass: 'text-center',
          thStyle: { width: '160px' },
          formatter: value => this.$options.filters.datetime(value),
          sortable: true,
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Status'),
          key: 'active',
          tdClass: 'text-center',
          thStyle: { width: '50px' },
          sortable: true,
        },
      ]
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/sale/promotion', [
      'setFilter',
      'setCurrentPage',
      'setPageSize',
      'fetchPromotions',
      'resetFilters',
    ]),
    ...mapActions('pages/sale/promotion/promotionMaintain', {
      stCleanPromotionMaintain: 'cleanState',
    }),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchPromotions()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    onCreatePromotion() {
      this.stCleanPromotionMaintain()
      this.$router.push({ name: 'promotion-add' })
    },

    async onUpdatePromotion(row) {
      const { id } = row.item
      // TODO: go to new update page
      this.$router.push({ name: 'promotion-maintain', params: { id } })
    },

    async activateDeactivatePromotion(row) {
      try {
        const confirm = await this.confirm()
        if (confirm) {
          const path = row.item.active ? 'deactivate' : 'activate'
          await this.$http({
            url: `/api/promotions/${path}/${row.item.id}`,
            method: 'PUT',
          })
          this.getData()
          this.showSuccess({
            message: this.$t(`${row.item.active === true ? 'Inativado' : 'Ativado'} com sucesso`),
          })
        }
      } catch (error) {
        this.showError({ error })
      }
    },
  },
}
</script>

<style></style>
