import { render, staticRenderFns } from "./PromotionList.vue?vue&type=template&id=970b58dc&"
import script from "./PromotionList.vue?vue&type=script&lang=js&"
export * from "./PromotionList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/echope/echope-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('970b58dc')) {
      api.createRecord('970b58dc', component.options)
    } else {
      api.reload('970b58dc', component.options)
    }
    module.hot.accept("./PromotionList.vue?vue&type=template&id=970b58dc&", function () {
      api.rerender('970b58dc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/sale/promotion/PromotionList.vue"
export default component.exports